@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
/*
 * Builtins
 * ------------------- */

body {
  font-family: 'Open Sans', sans-serif;
  max-width: 500px;
  max-height: 700px;
  background: #f4f4f4 !important;
  padding: 30px !important;
}

button {
  width: 100%;
  background: #02a1f7;
  height: 50px;
  color: #fff;
  font-size: 18px;
  box-shadow: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

button:hover {
  -webkit-transition: all .3s;
  transition: all .3s;
  background-color: #0274f7;
}

hr {
  border: 1px solid #d3d3d3;
}

/*
 * Checkbox
 * ------------------- */

.checkbox > input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 10px !important;
}

.checkbox > input:after {
  content: '\2714';
  position: absolute;
  top: -3px;
  left: 7px;
  color: #dbdbdb;
  font-size: 25px;
}

.checkbox > input:hover:after {
  color: #bdbdbd;
  -webkit-transition: all .3s;
  transition: all .3s;
}

/*.checkbox > input:checked:hover:after {
  color: #bdbdbd;
  transition: all .3s;
}*/

.checkbox > input:checked:after {
  content: '\2714';
  position: absolute;
  top: -3px;
  left: 7px;
  color: #02a1f7;
  font-size: 25px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.checkbox > span {
  position: relative;
  top: -12px;
  left: 10px;
}

/*
 * Buttons
 * ------------------- */

.buttonsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.buttonInContainerFirst {
  border-radius: 10px;
  width: 33%;
  height: 150px;
  padding-top: 20px;
  background-color: #fff;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}

.buttonInContainer:hover {
  background-color: #02a1f7 !important;
  color: #fff !important;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.iconButton {
  font-size: 60px;
}

/*
 * Typography
 * ------------------- */

.title {
  text-align: center;
}

.bold-text {
  font-weight: 600;
}

/*
 * Input
 * ------------------- */

.inputContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.inputContainer-item-1 {
  margin-top: 10px;
  width: 100%;
}

.inputContainer-item-1 label {
  width: 100%;
}

.inputContainer-item-1 input {
  min-width: 100%;
  max-width: 100%;
  height: 40px;
  border: 1px solid #02a1f7;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 18px;

  padding: 10px; /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.inputContainer-item-2 {
  width: 50%;
}

.inputContainer-item-2 label {
  width: 100%;
}

.inputContainer-item-2 input {
  width: 100%;
  height: 40px;
  border: 1px solid #02a1f7;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 18px;

  padding: 10px; /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.inputContainer-label {
  width: 100%;
  display: flex;
  justify-content: center;
}

.inputContainer-item-1-label {
  margin-top: 10px;
  width: 100%;
}

.inputContainer-item-1-label label {

}

.inputContainer-item-1-label input {
  border: 1px solid #02a1f7;
  border-radius: 10px;
}

.price-container {
  display: flex;
  /*justify-content: center;*/
  width: 100%;
}

.price-container p {
  font-size: 30px;
  font-weight: 500;
  /*text-align: center;*/
}

.inputContainer-item-3 {
  margin-top: 30px;
  width: 100%;
}

.inputContainer-item-3 label {
  width: 100%;
}

.inputContainer-item-3 input {
  display: flex;
  width: 100px;
  height: 40px;
  border: 1px solid #02a1f7;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 18px;

  padding: 10px; /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

/*
 * Fade in element
 * ------------------- */

.fadein {
  -webkit-animation: fadein 2s;
          animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */


